import { template as template_44f70f2d94fa4054bc8a6be72eaca430 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_44f70f2d94fa4054bc8a6be72eaca430(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_44f70f2d94fa4054bc8a6be72eaca430(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_44f70f2d94fa4054bc8a6be72eaca430(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
