import { template as template_2eee900de8e64a1d9fe6348c1fc1b255 } from "@ember/template-compiler";
const WelcomeHeader = template_2eee900de8e64a1d9fe6348c1fc1b255(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
